
/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/
@media (min-width: 1280px) {
    .navbar {
        padding: 0;
    }

    .navbar ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
    }

    .navbar li {
        color: white;
        position: relative;
        cursor: pointer;
    }

    .navbar>ul>li {
        white-space: nowrap;
        padding: 10px 0 10px 28px;
    }

    .navbar a,
    .navbar a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px;
        font-family: var(--font-primary);
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        text-transform: uppercase;
        white-space: nowrap;
        transition: 0.3s;
        position: relative;
    }

    .navbar a i,
    .navbar a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
    }

    .navbar>ul>li>a:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -6px;
        left: 0;
        background-color: var(--color-primary);
        visibility: hidden;
    }

    .navbar a:hover:before,
    .navbar li:hover>a:before,
    .navbar .active:before {
        visibility: visible;
        width: 100%;
    }

    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
        color: #fff;
    }

    .navbar .dropdown ul {
        display: block;
        position: absolute;
        left: 28px;
        top: calc(100% + 30px);
        margin: 0;
        padding: 10px 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        background: #fff;
        box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
        transition: 0.3s;
    }

    .navbar .dropdown ul li {
        min-width: 200px;
    }

    .navbar .dropdown ul a {
        padding: 10px 20px;
        font-size: 15px;
        text-transform: none;
        color: var(--color-default);
        font-weight: 400;
    }

    .navbar .dropdown ul a i {
        font-size: 12px;
    }

    .navbar .dropdown ul a:hover,
    .navbar .dropdown ul .active:hover,
    .navbar .dropdown ul li:hover>a {
        color: var(--color-primary);
    }

    .navbar .dropdown:hover>ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .navbar .dropdown .dropdown ul {
        top: 0;
        left: calc(100% - 30px);
        visibility: hidden;
    }

    .navbar .dropdown .dropdown:hover>ul {
        opacity: 1;
        top: 0;
        left: 100%;
        visibility: visible;
    }

    .mobile-nav-overlay {
        position: fixed;
        inset: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 9996;
    }
}

@media (min-width: 1280px) and (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

@media (min-width: 1280px) {

    .mobile-nav-show,
    .mobile-nav-hide {
        display: none;
    }
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
    .navbar {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 300px;
        bottom: 0;
        transition: 0.3s;
        z-index: 9997;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }


    .navbar ul {
        color: white;
        position: absolute;
        inset: 0;
        padding: 50px 0 10px 0;
        margin: 0;
        background: rgba(0, 0, 0, 0.8);
        overflow-y: auto;
        transition: 0.3s;
        z-index: 9998;
    }

    .navbar a,
    .navbar a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        font-family: var(--font-primary);
        font-size: 15px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.7);
        white-space: nowrap;
        text-transform: uppercase;
        transition: 0.3s;
    }

    .navbar a i,
    .navbar a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
    }

    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
        color: #fff;
        cursor: pointer;
    }

    .navbar .dropdown ul,
    .navbar .dropdown .dropdown ul {
        position: static;
        display: none;
        padding: 10px 0;
        margin: 10px 20px;
        transition: all 0.5s ease-in-out;
        border: 1px solid #222428;
    }

    .navbar .dropdown>.dropdown-active,
    .navbar .dropdown .dropdown>.dropdown-active {
        display: block;
    }

    .mobile-nav-show {
        position: relative;
        color: #fff;
        font-size: 28px;
        cursor: pointer;
        line-height: 0;
        transition: 0.5s;
        z-index: 9999;
        padding-right: 10px;
    }

    .mobile-nav-hide {
        color: #fff;
        font-size: 32px;
        cursor: pointer;
        line-height: 0;
        transition: 0.5s;
        position: fixed;
        right: 20px;
        top: 20px;
        z-index: 9999;
    }

    .mobile-nav-active {
        overflow: hidden;
    }

    .mobile-nav-active .navbar {
        right: 0;
    }

    .mobile-nav-active .navbar:before {
        content: "";
        position: fixed;
        inset: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 9996;
    }
}
