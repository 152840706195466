h4 {
    color: var(--color-primary);
}


.iconBox {
    margin-top: 50px;
}

.iconBox i {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    margin-right: 25px;
    font-size: 28px;
    width: 56px;
    height: 56px;
    border-radius: 4px;
    line-height: 0;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    transition: 0.3s;
}

.iconBox:hover i {
    background-color: var(--color-primary);
    color: #fff;
}

.iconBox h4 {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
}

.iconBox h4 p {
    color: #000;
    transition: 0.3s;
}

.iconBox h4 p:hover {
    color: var(--color-primary);
}

.iconBox p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}