.footer {
    color: #fff;
    background: url("../../public/assets/img/footer-bg.jpeg") top center no-repeat;
    background-size: cover;
    font-size: 14px;
    padding: 80px 0 60px 0;
    position: relative;
    text-align: center;
}

.footer li {
    cursor: pointer;
}

.footer:before {
    content: "";
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    inset: 0;
}

.footer .footer-content .footer-info {
    margin-bottom: 30px;
}

.footer .footer-content .footer-info h3 {
    font-size: 28px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
}

.footer .footer-content .footer-info h3 span {
    color: var(--color-primary);
}

.footer .footer-content .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: var(--font-primary);
    color: #fff;
}
.footer .footer-content .social-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .footer-content .social-links a {
    font-size: 18px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    line-height: 1;
    margin-right: 8px;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

.footer .footer-content .social-links a:hover {
    background: var(--color-primary);
    text-decoration: none;
}

.footer .footer-content h4 {
    font-size: 19px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

.footer .footer-content .footer-links {
    margin-bottom: 30px;
}

.footer .footer-content .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer .footer-content .footer-links ul li {
    padding: 8px 0;
}

.footer .footer-content .footer-links ul li:first-child {
    padding-top: 0;
}

.footer .footer-content .footer-links ul p {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

.footer .footer-content .footer-links ul p:hover {
    color: #fff;
}

.footer .footer-legal .copyright {
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.footer .footer-legal .credits {
    padding-top: 4px;
    font-size: 13px;
    color: #fff;
}

.footer .footer-legal .credits a {
    color: var(--color-primary);
}