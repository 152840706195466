.header {
    z-index: 997;
    position: absolute;
    padding: 30px 0;
    top: 0;
    left: 0;
    right: 0;
}

.header .logo {
    display: flex;
    align-items: center;
}


.header .logo h1 span {
    color: var(--color-primary);
}

.logoImage {
    width: 200px;
    height: auto;
}